
import { ReactElement, createContext, useContext } from "react";

const UuidToolContext = createContext<UuidToolType>(null!)

interface UuidToolType {

}

export function useUuidToolProvider(){
	return useContext(UuidToolContext)
} 


export function UuidToolProvider({ children }: { children: ReactElement }) {

	const value = {

    }
	return (
		<UuidToolContext.Provider value={value}>
			{children}
		</UuidToolContext.Provider>
	)
}
