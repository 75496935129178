import { Box, Tab, Tabs, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { ToolModel } from "../../model/tool-model";
import { RoutePath } from "../route/route-path";

interface RootPageType {
    children?: ReactNode
}

const RootPage = (props: RootPageType) => {
    const [tab, setTab] = useState<number>(0)

    const tools = [
        ToolModel.fromJson({
            id: 0,
            name: 'Web Tools',
            tools: [
                ToolModel.fromJson({
                    name: 'Hash tool',
                    path: RoutePath.initial,
                }), ToolModel.fromJson({
                    name: 'Url analytics',
                    path: RoutePath.urlTool,
                }), ToolModel.fromJson({
                    name: 'HTML tools',
                    path: RoutePath.htmlTool,
                }), ToolModel.fromJson({
                    name: 'JSON tools',
                    path: RoutePath.jsonTool,
                }), ToolModel.fromJson({
                    name: 'Javascript tools',
                    path: RoutePath.jsTool,
                }), ToolModel.fromJson({
                    name: 'CSS tools',
                    path: RoutePath.cssTool,
                }), ToolModel.fromJson({
                    name: 'XML tools',
                    path: RoutePath.xmlTool,
                }), ToolModel.fromJson({
                    name: 'Color tools',
                    path: RoutePath.urlTool,
                }), ToolModel.fromJson({
                    name: 'Markdown tools',
                    path: RoutePath.urlTool,
                }),
            ]
        }),
        ToolModel.fromJson({
            id: 1,
            name: 'Text Tools',
            tools: [
                ToolModel.fromJson({
                    name: 'Classic tools',
                    path: RoutePath.classic,
                }),
                ToolModel.fromJson({
                    name: 'Short url',
                    path: 'https://s.napthe247.io.vn/',
                }),
            ]
        }),
        ToolModel.fromJson({
            id: 2,
            name: 'Password Tools',
            tools: [
                ToolModel.fromJson({
                    name: 'Password generate',
                    path: RoutePath.passwordGenerate,
                }), ToolModel.fromJson({
                    name: 'Bcrypt Password',
                    path: RoutePath.bcryptGenerate,
                }),
            ]
        }),
        ToolModel.fromJson({
            id: 3,
            name: 'Time',
            tools: [
                ToolModel.fromJson({
                    name: 'Time stamp',
                    path: RoutePath.timeStamp,
                }),
              ToolModel.fromJson({
                    name: 'UUID',
                    path: RoutePath.uuid,
                }),
                
            ]
        }),
    ]
    function handleChange(event: React.SyntheticEvent<Element, Event>, value: number) {
        setTab(value)
    }
    return (
        <Box
            sx={{
                p: 4
            }}>
            <Typography variant="h1" textAlign={'center'}>
                <b>Hash App</b>
            </Typography>
            <Outlet />
            <Box mt={4} border={'1px solid'} borderRadius={2}>
                <Tabs value={tab} onChange={handleChange}>
                    {
                        tools.map(it => (
                            <Tab label={it.name} id={it.id?.toString()} />
                        ))
                    }
                </Tabs>
                <Box minHeight={300}>
                    {
                        tools.find(it => it.id === tab)?.tools?.map(it => (
                            <Box p={2}>
                                <Link to={it.path ?? ''} target={it.path?.startsWith('http') ? '_blank' : ''}>{it.name}</Link>
                            </Box>
                        ))
                    }
                </Box>

            </Box>
        </Box>
    );
}

export default RootPage