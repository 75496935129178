
import { UuidToolProvider } from "./provider";
import * as uuid from 'uuid';
function UuidToolPage() {
    return (
        <UuidToolProvider>
            <View />
        </UuidToolProvider>
    );
}

function View() {

    const v1 = uuid.v1()
    const v4 = uuid.v4()
    const v6 = uuid.v6()
    const v7 = uuid.v7()


    function makeCopy(value: string) {
        navigator.clipboard.writeText(value)
    }
    return (
        <div>
            <h1>UUID</h1>
            <table>
                <tbody>
                    <tr>
                        <td>NIL</td>
                        <td>{uuid.NIL}</td>
                    </tr>
                    <tr>
                        <td>MAX</td>
                        <td>{uuid.MAX}</td>
                    </tr>
                    <tr>
                        <td>V1</td>
                        <td>{v1}</td>
                        <td>
                            <button onClick={() => makeCopy(v1)}>Copy</button>
                        </td>
                    </tr>
                    <tr>
                        <td>V4</td>
                        <td>{v4}</td>
                        <td>
                            <button onClick={() => makeCopy(v4)}>Copy</button>
                        </td>
                    </tr>
                    <tr>
                        <td>V6</td>
                        <td>{v6}</td>
                        <td>
                            <button onClick={() => makeCopy(v6)}>Copy</button>
                        </td>
                    </tr>
                    <tr>
                        <td>V7</td>
                        <td>{v7}</td>
                        <td>
                            <button onClick={() => makeCopy(v7)}>Copy</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default UuidToolPage
